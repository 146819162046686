import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./collabops@gen.scss";

const GeneratedUseCasesCollabops = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          PagerDuty活用例：CollabOpsツールのインテグレーション｜PagerDuty正規販売代理店（株）Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="PagerDutyは、Slack、Microsoft Teams、Zoom、Google Chatなどのコラボレーションオペレーション（CollabOps）ツールとの統合により、迅速なコラボレーションとインシデントへの対応を支援します。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="PagerDutyは、Slack、Microsoft Teams、Zoom、Google Chatなどのコラボレーションオペレーション（CollabOps）ツールとの統合により、迅速なコラボレーションとインシデントへの対応を支援します。"
        />

        <meta
          property="og:title"
          content="PagerDuty活用例：CollabOpsツールのインテグレーション｜PagerDuty正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="PagerDutyは、Slack、Microsoft Teams、Zoom、Google Chatなどのコラボレーションオペレーション（CollabOps）ツールとの統合により、迅速なコラボレーションとインシデントへの対応を支援します。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/use-cases/collabops/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/use-cases/collabops/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Solution"
        textGreen="PagerDuty活用例：CollabOpsツールの統合"
        overview=""
      />

      <div className="collabOpsWrapper">
        <div className="px-[25px] xl:px-0">
          <p className="text-lg leading-8 text-black text-center mb-[100px]">
            スマートで迅速なインシデント処理には、コラボレーションが欠かせません。分散型の社会では、作業者はコラボレーションツールによってリアルタイムな会話やビデオ会議でつながり、ドキュメントを共有できます。Slack、Microsoft
            Teams、Zoomなどのコラボレーションオペレーション（CollabOps）ツールは、日々の作業やコミュニケーションのための主要なインターフェイスであることが多いものです。これらのツールとPagerDutyを統合すると、頻繁なコンテキストの切り替えを回避して時間を節約し、従来手作業でミスが起こりがちだったプロセスを合理化することができます。
            <br />
            <br />
            CollabOpsツールは、DevOps、CSOps、BizOps、ITOpsを成功させる全ての組織の必須ツールとなっています。CollabOpsにPagerDutyを追加してコラボレートすることで、レスポンダーは迅速かつ容易にインシデントに対応できるようになります。これにより、平均応答時間（MTTA）と平均解決時間（MTTR）が数分短縮され、コスト削減とダウンタイムの低減が実現されます。例えばPagerDutyのSlack統合により、1インシデントあたり平均1分のMTTAとMTTRが短縮されます。
          </p>
          <h2 className="text-2xl leading-8 text-black text-center mb-[50px] after:hidden">
            PagerDutyをデジタル運用の中心に
          </h2>
          <div className="flex gap-y-10 gap-x-[45px] flex-col md:flex-row mb-[100px]">
            <div className="flex-1">
              <svg
                width={80}
                className="mb-[30px] mx-auto"
                height={78}
                viewBox="0 0 80 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M78.5341 50.058C76.5673 47.9133 72.9015 48.3691 70.4491 50.1836C68.2085 52.0489 56.8852 59.5258 56.8852 59.5258H38.2684L38.2091 59.5555C37.2285 59.5216 36.4623 58.7005 36.4921 57.7195C36.5274 56.7403 37.3527 55.9714 38.3263 56.0052H51.9848C54.7376 56.0052 56.9713 53.7419 56.9713 50.9891C56.9713 48.2319 54.7377 45.9997 51.9848 45.9997C49.4902 45.9997 44.5063 45.9997 32.0388 45.9997C19.5698 45.9997 16.9779 51.2388 14.8105 54.8355V75.4406L38.1698 77.8492C46.9588 78.3303 51.1735 77.8492 57.4357 72.5523C57.4357 72.5523 76.1343 58.8895 78.3848 57.0186C80.5251 55.0544 80.5024 52.2028 78.5341 50.058Z"
                  fill="#53B54B"
                />
                <path
                  d="M10.8366 49.9747H0V76.3436H10.8366V49.9747Z"
                  fill="#53B54B"
                />
                <path
                  d="M41.2399 40.0414C45.6623 40.0414 49.2474 36.4563 49.2474 32.0339C49.2474 27.6115 45.6623 24.0264 41.2399 24.0264C36.8175 24.0264 33.2324 27.6115 33.2324 32.0339C33.2324 36.4563 36.8175 40.0414 41.2399 40.0414Z"
                  fill="#53B54B"
                />
                <path
                  d="M60.2614 27.0038C64.0345 27.0038 67.0922 23.946 67.0922 20.1744C67.0922 16.4027 64.0345 13.345 60.2614 13.345C56.4912 13.345 53.4336 16.4027 53.4336 20.1744C53.4336 23.946 56.4912 27.0038 60.2614 27.0038Z"
                  fill="#53B54B"
                />
                <path
                  d="M43.4052 11.6858C46.6308 11.6858 49.2453 9.07112 49.2453 5.84705C49.2453 2.62158 46.6306 0.00689697 43.4052 0.00689697C40.1811 0.00689697 37.5664 2.62158 37.5664 5.84705C37.5664 9.07112 40.1811 11.6858 43.4052 11.6858Z"
                  fill="#53B54B"
                />
              </svg>
              <p className="text-lg leading-7 text-black text-center">
                AIを活用したイベントインテリジェンスを用いてイベントを理解・監視することで、インシデント対応やオンコール管理をトリガーすることができます。主要なインシデントの診断とワークフローの自動化も提供されます。
              </p>
            </div>
            <div className="flex-1">
              <svg
                className="mx-auto mb-[30px]"
                width="80px"
                height="78px"
                viewBox="0 0 80 76"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.4444 15.5599C21.8016 15.5599 24.5242 12.8385 24.5242 9.48112C24.5242 6.12268 21.8016 3.40002 18.4444 3.40002C15.0859 3.40002 12.3633 6.12268 12.3633 9.48112C12.3633 12.8385 15.0858 15.5599 18.4444 15.5599Z"
                  fill="#53B54B"
                />
                <path
                  d="M35.497 3.81125H75.093V36.0387H35.497V33.3478C34.9795 33.3392 34.4609 33.2941 33.952 33.2014L33.786 33.1709L32.1152 32.7084V39.4205H78.476V37.7289V0.429688H32.1152V16.6241L35.497 17.8066V3.81125Z"
                  fill="#53B54B"
                />
                <path
                  d="M36.5195 54.0442C36.5195 57.4014 39.2409 60.124 42.6006 60.124C45.9578 60.124 48.6805 57.4014 48.6805 54.0442C48.6805 50.6858 45.9578 47.9644 42.6006 47.9644C39.2409 47.9644 36.5195 50.6858 36.5195 54.0442Z"
                  fill="#53B54B"
                />
                <path
                  d="M42.6001 63.1628C36.8973 63.1628 31.8315 65.0642 30.5684 71.3869L30.6698 75.5703H54.5304L54.6304 71.3869C53.3673 65.0642 48.3016 63.1628 42.6001 63.1628Z"
                  fill="#53B54B"
                />
                <path
                  d="M61.8867 54.0442C61.8867 57.4014 64.6094 60.124 67.9678 60.124C71.325 60.124 74.0477 57.4014 74.0477 54.0442C74.0477 50.6858 71.325 47.9644 67.9678 47.9644C64.6094 47.9644 61.8867 50.6858 61.8867 54.0442Z"
                  fill="#53B54B"
                />
                <path
                  d="M67.9692 63.1628C62.2664 63.1628 57.2006 65.0642 55.9375 71.3869L56.0389 75.5703H79.8995L80.0008 71.3869C78.7364 65.0642 73.6719 63.1628 67.9692 63.1628Z"
                  fill="#53B54B"
                />
                <path
                  d="M57.5054 24.0731C56.1288 24.0731 55.0123 25.1873 55.0073 26.5628L45.016 30.2556C44.5582 29.7259 43.8896 29.3842 43.1341 29.3842C41.7538 29.3842 40.6348 30.5033 40.6348 31.8836C40.6348 33.2639 41.7538 34.383 43.1341 34.383C44.5118 34.383 45.6273 33.2688 45.6323 31.892L55.6234 28.2005C56.0812 28.7302 56.7498 29.0719 57.5052 29.0719C58.8855 29.0719 60.0046 27.9528 60.0046 26.5725C60.0046 26.0403 59.8362 25.5509 59.5531 25.1459L65.9599 15.8675C66.154 15.9164 66.3552 15.9505 66.5652 15.9505C67.9454 15.9505 69.0646 14.8314 69.0646 13.4511C69.0646 12.0708 67.9455 10.9517 66.5652 10.9517C65.1849 10.9517 64.0659 12.0708 64.0659 13.4511C64.0659 13.9831 64.2343 14.4725 64.5174 14.8777L58.1105 24.1561C57.9166 24.1072 57.7154 24.0731 57.5054 24.0731Z"
                  fill="#53B54B"
                />
                <path
                  d="M38.4025 28.907L48.2144 24.2122C49.5384 23.652 50.1584 22.1266 49.5983 20.8013C49.0394 19.4772 47.5126 18.8584 46.1886 19.4174L37.3664 22.4317L29.3839 19.6408C28.2197 19.2333 27.0115 18.9695 25.7875 18.8513L20.4069 27.1461V22.7052H17.1265V27.1778L11.1003 19.9044C7.8603 21.2442 5.40483 23.7802 4.52983 28.1578L0.0632656 46.8038C-0.250484 48.2084 0.633265 49.5984 2.0378 49.912C3.43998 50.2255 4.82998 49.3408 5.14358 47.9386L9.84202 35.5153L11.0576 72.4864C11.0576 74.19 12.4378 75.5703 14.1414 75.5703C15.8462 75.5703 17.2265 74.19 17.2265 72.4864L18.453 50.4392L19.6758 72.4864C19.6758 74.19 21.0586 75.5703 22.7609 75.5703C24.4647 75.5703 25.8459 74.19 25.8459 72.4864L27.5812 27.562L34.624 29.5134C35.9153 29.7491 37.249 29.5342 38.4025 28.907Z"
                  fill="#53B54B"
                />
                <path
                  d="M20.4073 18.9683H17.127V21.2675H20.4073V18.9683Z"
                  fill="#53B54B"
                />
              </svg>
              <p className="text-lg leading-7 text-black text-center">
                CollabOps統合の力で、チームの動員にインシデント通知カード経由での動的な会議ブリッジとリアルタイムのインシデント可視化、インシデントアップデート、ステークホルダーとのコミュニケーションを実現しましょう。
              </p>
            </div>
            <div className="flex-1">
              <svg
                className="mx-auto mb-[30px]"
                width="80px"
                height="76px"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.7338 64.3456H29.3327C30.217 64.3456 30.9336 63.6816 30.9336 62.8619V44.1559C30.9336 43.3363 30.217 42.6716 29.3327 42.6716H16.7338C15.8497 42.6716 15.1328 43.3363 15.1328 44.1559V62.862C15.1328 63.6816 15.8497 64.3456 16.7338 64.3456Z"
                  fill="#50B448"
                />
                <path
                  d="M39.7048 36.8878C38.8204 36.8878 38.1035 37.5525 38.1035 38.3716V62.8619C38.1035 63.6816 38.8204 64.3456 39.7048 64.3456H52.3034C53.1877 64.3456 53.9046 63.6816 53.9046 62.8619V38.3716C53.9046 37.5525 53.1877 36.8878 52.3034 36.8878H39.7048Z"
                  fill="#50B448"
                />
                <path
                  d="M61.0742 27.8791V62.862C61.0742 63.6817 61.7911 64.3458 62.6752 64.3458H75.2741C76.1581 64.3458 76.8753 63.6817 76.8753 62.862V27.8791C76.8753 27.0594 76.1581 26.3953 75.2741 26.3953H62.6752C61.7911 26.3953 61.0742 27.0594 61.0742 27.8791Z"
                  fill="#50B448"
                />
                <path
                  d="M77.4103 71.1084H8.89094V2.58906V0H0V2.58906V71.1084V77.4097V80H2.58969H8.89094H77.4103H80V71.1084H77.4103Z"
                  fill="#50B448"
                />
                <path
                  d="M35.0348 18.598L48.0172 31.5137L66.6788 12.9473L70.9391 17.1856L73.8175 0L56.5439 2.86437L60.8037 7.10266L48.0172 19.8242L35.0348 6.90797L15.1328 26.7072L21.0077 32.5525L35.0348 18.598Z"
                  fill="#50B448"
                />
              </svg>
              <p className="text-lg leading-7 text-black text-center">
                PagerDutyをCollabOpsに統合することで、レスポンダーはその場でインシデント対応と迅速な解決に着手できるようになります。インシデントの修正を効率化し、運用の成熟度をより早期に向上させることができます。
              </p>
            </div>
          </div>
          <img
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/IMAGE_13_a7d0a3608c.png"
            width="1080px"
            height="501px"
            loading="lazy"
            className="mb-[100px] object-cover"
            alt="relation"
          />
          <div className="flex flex-col-reverse md:flex-row gap-y-10 gap-x-[50px] mb-[60px]">
            <div className="flex-1 flex justify-center items-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Pager_Duty_and_Slack_283abd9a8d.svg"
                width="500px"
                height="85px"
                loading="lazy"
                className="bg-cover"
                alt="PagerDuty＋Slack"
              />
            </div>
            <div className="flex-1">
              <h3 className="text-2xl leading-8 text-black mb-[30px]">
                PagerDuty＋Slack
              </h3>
              <p className="text-lg leading-8 text-black">
                Workflow
                BuilderからMetaDataの使用まで、現代の多くのチームはコラボレーション、柔軟性、自動化にSlackを利用しています。物事がスムーズに進んでいるときはもちろん、そうでないときでも全員が同じ見解を共有することに役立ちます。
                <br />
                <br />
                Slackのコラボレーション機能をPagerDutyのインシデント管理に取り入れると、迅速な解決とコスト削減に貢献します。
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-y-10 gap-x-[50px] mb-[60px]">
            <div className="flex-1">
              <h3 className="text-2xl leading-8 text-black mb-[30px]">
                Microsoft Teams
              </h3>
              <p className="text-lg leading-8 text-black">
                現代の多くの組織では、コラボレーション、データ共有、アプリ開発の合理化のためにMicrosoft
                Teamsが活用されています。
                <br />
                <br />
                PagerDutyとMicrosoft
                Teamsの統合により、コラボレーションが効率化され、インシデントの確認と解決にかかる時間が短縮されます。
              </p>
            </div>
            <div className="flex-1 flex justify-center items-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Teams_163f554292.svg"
                width="161px"
                height="150px"
                loading="lazy"
                className="bg-cover"
                alt="Microsoft Teams"
              />
            </div>
          </div>
          <div className="flex flex-col-reverse md:flex-row gap-y-10 gap-x-[50px] mb-[60px]">
            <div className="flex-1 flex justify-center items-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Zoom_06f519e3ab.svg"
                width="321px"
                height="150px"
                loading="lazy"
                className="bg-cover"
                alt="Zoom"
              />
            </div>
            <div className="flex-1">
              <h3 className="text-2xl leading-8 text-black mb-[30px]">Zoom</h3>
              <p className="text-lg leading-8 text-black">
                コロナ禍以降、分散型が進んだ社会では、オフィスや在宅からビデオチャットや電話でインシデント管理を行うことが、迅速なインシデント解決に不可欠です。
                <br />
                <br />
                PagerDutyとZoomの統合により、ワンタッチでその場でZoomミーティングを作成し、レスポンダーがどこにいても、より早く、より低コストで対応チームを編成できます。
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-y-10 gap-x-[50px] mb-[100px]">
            <div className="flex-1">
              <h3 className="text-2xl leading-8 text-black mb-[30px]">
                Google Chat
              </h3>
              <p className="text-lg leading-8 text-black">
                PagerDuty for Google Chatを使うと、レスポンダーはGoogle
                Chatの会話から直接インシデントに対処することができ、デスクからでも出先の電話からでも、常にインシデント対応の輪にいることができます。
              </p>
            </div>
            <div className="flex-1 flex justify-center items-center">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Google_Chat_95adc29e38.svg"
                width="144px"
                height="150px"
                loading="lazy"
                className="bg-cover"
                alt="Google Chat"
              />
            </div>
          </div>
          <h3 className="text-center font-bold text-black text-2xl leading-8 mb-10">
            現在お使いのツールとの連携
          </h3>
          <p className="text-center text-lg leading-8 text-black mb-[30px]">
            今お使いのツールで動作するため、プロセスを変更する必要はありません。
            <br />
            <br />
            650以上のネイティブな統合機能と、拡張可能なPagerDuty
            APIを使用してワークフローを構築・カスタマイズする機能により、あらゆるツールからデータを統合し、ITインフラへのインサイトを提供します。強化されたEvent
            API
            v2は、全ての受信イベントを共通のフィールドに自動的に正規化します。
            <br />
            <br />
            双方向の拡張機能によりツール切り替えの手間はなく、インラインで問題を修正し、任意のChatOpsツールで運用関連のタスクを自動化可能。現在お使いのツールとよくなじみます。
          </p>
          <img
            src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/integration_diagram_4_ae3b5aa54e.svg"
            width="600px"
            height="333px"
            loading="lazy"
            className="flex-1 mx-auto"
            alt="Group"
          />
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedUseCasesCollabops;
